<template>
	<section id="tw-view-404">
		<div class="bg-white">
			<div class="p-4 py-5">
				<div class="d-flex justify-content-center mt-5">
					<h2 class="display-1"><b>404</b></h2>
				</div>
				<div class="d-flex justify-content-center mb-5">
					<h4>Not Found</h4>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: '404',
		components: {

		},
		data: () => ({

		}),
		mounted() {

		},
		methods: {

		},
		mixins: []
    }
</script>